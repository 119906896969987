import axios from "axios";

export const list = (params, page, pageSize) => {
  return axios.get("/deviceYmx/list", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};
