import { reactive, ref } from "vue";
import { list } from "@/api/maintenance/deviceYmxHistoryRecordApi";
import useMessage from "@/hooks/useMessage";
import { listAll } from "../../api/parking/parkingLotApi";
const useDeviceYmxHistoryRecord = () => {
  const { showErr } = useMessage();
  const loading = ref(false);
  const dataList = reactive({ list: [] });
  const total = ref(0);
  const parkingLotList = reactive([]);
  const geomagneticVo = reactive({
    sn: "",
    parkingPlaceId: null,
  });
  const messageTypes = ref([
    { value: 1, label: "心跳数据" },
    { value: 2, label: "车位状态" },
    { value: 3, label: "设备配置" },
  ]);

  const columns = [
    {
      title: "序号",
      align: "center",
      width: "80px",
      customRender: ({ index }) => {
        return index + 1;
      },
    },
    {
      title: "设备编号",
      align: "center",
      dataIndex: "sn",
    },
    {
      title: "车位编号",
      align: "center",
      dataIndex: "parkingPlaceCode",
    },
    {
      title: "消息类型",
      align: "center",
      dataIndex: "messageType",
      slots: { customRender: "messageType" }

    },
    {
      title: "包序号",
      align: "center",
      dataIndex: "sequence",
    },
    {
      title: "车位状态",
      align: "center",
      dataIndex: "park",
      slots: { customRender: "park" }
    },
    {
      title: "设备电量",
      align: "center",
      dataIndex: "batt",
    },
    {
      title: "消息时间",
      align: "center",
      dataIndex: "messageTs",
    },
    {
      title: "创建时间",
      align: "center",
      dataIndex: "createTime",
    },
    {
      title: "数据内容",
      align: "center",
      dataIndex: "messageJson",
    },
  ];
  const loadData = async (page, pageSize) => {
    loading.value = true;
    let p = {
      sn: params.sn,
      parkingLotId: params.parkingLotId,
      parkingPlaceCode: params.parkingPlaceCode,
      createTimeArr: params.createTimeArr? params.createTimeArr.toString(): [],
      messageType: params.messageType,
    }
    try {
      let res = await list(p, page, pageSize);
      dataList.list = res.data;
      total.value = res.total;
      loading.value = false;
    } catch (e) {
      loading.value = false;
      await showErr(e);
    }
  };

  const getParkingLotList = async () => {
    try {
      let { data } = await listAll();
      parkingLotList.push(...data);
    } catch (e) {
      showErr(e);
    }
  };

  const params = reactive({
    sn: "",
    parkingLotId: null,
    parkingPlaceId: null,
    createTimeArr: [],
    messageType: null,
  });
  return {
    loading,
    params,
    dataList,
    total,
    columns,
    loadData,
    getParkingLotList,
    geomagneticVo,
    parkingLotList,
    messageTypes
  };
};

export default useDeviceYmxHistoryRecord;
